import React from "react";
import placeholderImage from '../../img/components/navbar/raphael-logo.png';

function GalleryPreviewCard({galleryPost: {title, date, coverPicture, slug}}) {
  return (
    <div className="max-w-sm m-10 rounded overflow-hidden transition-all shadow-md hover:shadow-xl hover:bg-gray-200">
      <a href={slug}>
        <img className={`w-full h-64 ${coverPicture ? "object-cover" : "object-contain"}`} src={coverPicture || placeholderImage} alt={title}/>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{title}</div>
          <div className="text-sm mb-2">{date}</div>
        </div>
      </a>
    </div>
  );
}

export default GalleryPreviewCard;
