import React from "react";
import {graphql} from "gatsby";

import Layout from "../../components/layout";
import ImageCaptionSection from "../../components/imageCaptionSection";
import HelpMethodsSection from "../../components/helpMethodsSection";
import GalleryPreviewCard from "./galleryPreviewCard";
import Pagination from "../../components/pagination";

function GalleryIndex(props) {
  const {data, pageContext: {numPages, currentPage}} = props

  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  const galleryPosts = data.allMarkdownRemark.edges.map(galleryPost => ({
    ...galleryPost.node.fields,
    ...galleryPost.node.frontmatter,
  }));

  return (
    <Layout activeLink="/know-us" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <div className="flex flex-row flex-wrap justify-evenly mt-20">
        {galleryPosts.map((galleryPost, index) => <GalleryPreviewCard key={index} galleryPost={galleryPost}/>)}
      </div>
      <Pagination totalPages={numPages} currentPage={currentPage} baseURL="/archives/"/>

      <HelpMethodsSection/>
    </Layout>
  );
}

export default GalleryIndex;

export const galleryIndexQuery = graphql`
  query GalleryIndexQuery($skip: Int!, $limit: Int!) {
    markdownRemark(frontmatter: {templateKey: {eq: "gallery"}}) {
      frontmatter {
        imageCaptionSection {
          subTitle
          title
          imageCaptionBackground
        }
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/pages/archives/"}}
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            coverPicture
          }
        }
      }
    }
  }
`;
